<template>
  <div class="table-input">
    <div v-if="loadingPrices" class="loading loading--content" id="loading">
      <div class="loading__body">
        <div class="effect-1 loading__effects"></div>
        <div class="effect-2 loading__effects"></div>
        <div class="effect-3 loading__effects"></div>
      </div>
    </div>
    <div v-if="!loadingPrices" class="table-input__wrapper">
      <div class="table-input__header">
        <div class="table-input__row">
          <div class="table-input__col">
            <div class="table-input__title">Группа товаров</div>
          </div>
          <div class="table-input__col">
            <div class="table-input__title">Тип цены (Опт)</div>
          </div>
          <div class="table-input__col">
            <div class="table-input__title">Наценка (Нарезка)</div>
          </div>
        </div>
      </div>
      <div class="table-input__body">
        <div class="table-input__row" v-for="item in prices" :key="item.price_group_id">
          <div class="table-input__col">
            <div class="table-input__text">{{ item.price_group_name }}</div>
          </div>
          <div class="table-input__col">
            <div class="table-input__text">{{ item.price_type_name }}</div>
          </div>
          <div class="table-input__col">
            <input
              type="text"
              name="markup"
              :data-price-id="item.price_group_id"
              :value="item.markup"
              @change="onMarkupChange"
              class="table-input__input"
              placeholder="Введите значение"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prices',
  data() {
    return {
      prices: {},
      loadingPrices: true
    }
  },
  mounted() {
    this.loadingPrices = true
    this.$store.dispatch('client/GET_PRICES', this.$route.params.id)
      .then(response => {
        this.prices = response.data
        this.loadingPrices = false
      })
  },
  methods: {
    onMarkupChange(event) {
      this.submitPriceGroup(event.target.value, event.target.getAttribute('data-price-id'))
    },
    submitPriceGroup(value, priceId) {
      this.$store.dispatch('client/PUT_PRICES', { markup: value, price_group_code: priceId, client_id: this.$route.params.id })
        .then(res => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: `${res.data.message}`
          })
        })
    }
  }
}
</script>
